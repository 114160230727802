import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import '../assets/scss/newstyle.scss'
import blogimg1 from '../assets/img/blogimg1.jpg'
import blogimg3 from '../assets/img/article2.png'
import blogimg4 from '../assets/img/ICDevent.png'
import blogimg5 from '../assets/img/article4.png'
import blogimg6 from '../assets/img/smart.jpg'
import blogimg7 from '../assets/img/blogimg3.jpg'
import blogimg8 from '../assets/img/image7.png'
import blogimg9 from '../assets/img/TUBERS5.png'
import blogimg10 from '../assets/img/tubers11.jpg'
import blogimg11 from '../assets/img/Journées.jpg'
import blogimg12 from '../assets/img/utility.jpg'
import blogimg13 from '../assets/img/Trade.png'

import SingleBlog from './Article1'

const BlogList = () => {




  return (
    <div id="layout" className="pure-g">
      <div className="content pure-u-1 pure-u-md-3-4">
        <div>
          <div className="posts" >
		  <section >
                	<div class="container py-2">
		<div class="h1 text-center text-dark" id="pageHeaderTitle"></div>

		<article class="postcard light blue">
			<a class="postcard__img_link">
				<img class="postcard__img"  title="" src={blogimg13}/>
			</a>
			<div class="postcard__text t-dark">
				<h1 class="postcard__title blue"><a >TUBERS comes back to the Vision, Robotics & Motion Trade Fair</a></h1>
				<div class="postcard__subtitle small">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
						12/09/2024
					</time>
				</div>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt">Our TUBERS project took part in the Vision, Robotics and Motion Trade Fair through our
				partner Demcon for a second time.</div>
				<ul class="postcard__tagbox">
					
          <Link
                   to ='/TUBERS comes back to the Vision, Robotics &amp; Motion Trade Fair'
                    className="buttonaki">
                    Read More
                  </Link>
				</ul>
			</div>
		</article>
    </div>
              
              </section>

		  <section >
                	<div class="container py-2">
		<div class="h1 text-center text-dark" id="pageHeaderTitle"></div>

		<article class="postcard light blue">
			<a class="postcard__img_link">
				<img class="postcard__img"  title="" src={blogimg10}/>
			</a>
			<div class="postcard__text t-dark">
				<h1 class="postcard__title blue"><a >M21 Meeting finds TUBERS in Bordeaux</a></h1>
				<div class="postcard__subtitle small">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
						30/05/2024
					</time>
				</div>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt">Coming closer to the two-year mark, the M21 meeting for our Horizon Europe TUBERS project
was in order.</div>
				<ul class="postcard__tagbox">
					
          <Link
                   to ='/M21 Meeting finds TUBERS in Bordeaux'
                    className="buttonaki">
                    Read More
                  </Link>
				</ul>
			</div>
		</article>
    </div>
              
              </section>
			  <section >
                	<div class="container py-2">
		<div class="h1 text-center text-dark" id="pageHeaderTitle"></div>

		<article class="postcard light blue">
			<a class="postcard__img_link">
				<img class="postcard__img"  title="" src={blogimg12}/>
			</a>
			<div class="postcard__text t-dark">
				<h1 class="postcard__title blue"><a >TUBERS project joins the UK Utility Week 2024</a></h1>
				<div class="postcard__subtitle small">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
						28/05/2024
					</time>
				</div>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt">On May 21-22, 2024, TUBERS attended the Utility Week Congress in Birmingham, UK.</div>
				<ul class="postcard__tagbox">
					
          <Link
                   to ='/TUBERS project joins the UK Utility Week 2024'
                    className="buttonaki">
                    Read More
                  </Link>
				</ul>
			</div>
		</article>
    </div>
              
              </section>
		  <section >
                	<div class="container py-2">
		<div class="h1 text-center text-dark" id="pageHeaderTitle"></div>

		<article class="postcard light blue">
			<a class="postcard__img_link">
				<img class="postcard__img"  title="" src={blogimg9}/>
			</a>
			<div class="postcard__text t-dark">
				<h1 class="postcard__title blue"><a >TUBERS research gets published for the first time</a></h1>
				<div class="postcard__subtitle small">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
						14/05/2024
					</time>
				</div>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt">Our TUBERS consortium is excited to announce its first project publication! The paper,
"Enhancing Material Thickness Measurement: Ultrasonic Sensor Data Analysis and Thickness
Prediction Using Neural Networks,” was published in the “Applications of Modelling and
Simulation" journal in April 2024.</div>
				<ul class="postcard__tagbox">
					
          <Link
                   to ='/TUBERS research gets published for the first time'
                    className="buttonaki">
                    Read More
                  </Link>
				</ul>
			</div>
		</article>
    </div>
              
              </section>
		  <section >
                	<div class="container py-2">
		<div class="h1 text-center text-dark" id="pageHeaderTitle"></div>

		<article class="postcard light blue">
			
				<img class="postcard__img"  title="" src={blogimg8}/>
		
			<div class="postcard__text t-dark">
				<h1 class="postcard__title blue"><a href="#">TUBERS attending the WWT Innovation & Smart Water Conference 2024</a></h1>
				<div class="postcard__subtitle small">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
						29/03/2024
					</time>
				</div>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt">Our TUBERS partner, Alsymex, represented by Joel vanden Bosch, attended the WWT
Innovation and Smart Water Conference organised by Utility Week Group in Birmingham on
March 14, 2024.</div>
				<ul class="postcard__tagbox">
					
          <Link
                   to ='/TUBERS attending the WWT Innovation & Smart Water Conference 2024'
                    className="buttonaki">
                    Read More
                  </Link>
				</ul>
			</div>
		</article>
    </div>
              
              </section>
			  <section >
                	<div class="container py-2">
		<div class="h1 text-center text-dark" id="pageHeaderTitle"></div>

		<article class="postcard light blue">
			<a class="postcard__img_link">
				<img class="postcard__img"  title="" src={blogimg11}/>
			</a>
			<div class="postcard__text t-dark">
				<h1 class="postcard__title blue"><a >TUBERS at the France-Morocco Economic Days</a></h1>
				<div class="postcard__subtitle small">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
						13/03/2024
					</time>
				</div>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt">Our project, through our partner Alsymex, was invited to participate in the Summit Day during
				the “Journées économiques France—Maroc/L'eau: enjeux et perspectives” event.</div>
				<ul class="postcard__tagbox">
					
          <Link
                   to ='/TUBERS at the France-Morocco Economic Days'
                    className="buttonaki">
                    Read More
                  </Link>
				</ul>
			</div>
		</article>
    </div>
              
              </section>
		  <section >
                	<div class="container py-2">
		<div class="h1 text-center text-dark" id="pageHeaderTitle"></div>

		<article class="postcard light blue">
			<a class="postcard__img_link" >
				<img class="postcard__img"  title="" src={blogimg7}/>
			</a>
			<div class="postcard__text t-dark">
				<h1 class="postcard__title blue"><a >Project TUBERS & RIMA Alliance: Main Challenges in Inspection & Maintenance Robotics</a></h1>
				<div class="postcard__subtitle small">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
						29/01/2024
					</time>
				</div>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt">Since its initiation, our TUBERS project has sought to develop a system of robotic technologies
that will inspect, monitor, and repair micro-leaks in drinking water pipe distribution networks.</div>
				<ul class="postcard__tagbox">
					
          <Link
                   to ='/Project TUBERS & RIMA Alliance:Main Challenges in Inspection & Maintenance Robotics'
                    className="buttonaki">
                    Read More
                  </Link>
				</ul>
			</div>
		</article>
    </div>
              
              </section>

		  <section >
                	<div class="container py-2">
		<div class="h1 text-center text-dark" id="pageHeaderTitle"></div>

		<article class="postcard light blue">
			<a class="postcard__img_link" >
				<img class="postcard__img"  title="" src={blogimg6}/>
			</a>
			<div class="postcard__text t-dark">
				<h1 class="postcard__title blue"><a >Project TUBERS attends Smart Water Utilities 2023</a></h1>
				<div class="postcard__subtitle small">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
						05/07/2023
					</time>
				</div>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt">The Smart Water Utilities conference took place in Amsterdam between 28 - 29 June 2023,
uniting experts, researchers,</div>
				<ul class="postcard__tagbox">
					
          <Link
                   to ='/Project TUBERS attends Smart Water Utilities 2023'
                    className="buttonaki">
                    Read More
                  </Link>
				</ul>
			</div>
		</article>
    </div>
              
              </section>

		  <section >
                	<div class="container py-2">
		<div class="h1 text-center text-dark" id="pageHeaderTitle"></div>

		<article class="postcard light blue">
			<a class="postcard__img_link" >
				<img class="postcard__img"  title="" src={blogimg5}/>
			</a>
			<div class="postcard__text t-dark">
				<h1 class="postcard__title blue"><a >Demcon attends the Vision, Robotics and Motion event</a></h1>
				<div class="postcard__subtitle small">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
                 13/06/2023
					</time>
				</div>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt">Between the 7th and 8th of June 2023, Demcon participated as an exhibitor in the Vision,
Robotics &amp; Motion trade fair and convention in ‘s-Hertogenbosch,</div>
				<ul class="postcard__tagbox">
					
          <Link
                   to ='/Demcon attends the Vision, Robotics and Motion event'
                    className="buttonaki">
                    Read More
                  </Link>
				</ul>
			</div>
		</article>
    </div>
              
              </section>

		  <section >
                	<div class="container py-2">
		<div class="h1 text-center text-dark" id="pageHeaderTitle"></div>

		<article class="postcard light blue">
			<a class="postcard__img_link">
				<img class="postcard__img"  title="" src={blogimg4}/>
			</a>
			<div class="postcard__text t-dark">
				<h1 class="postcard__title blue"><a >The TUBERS tech joins the ICD ''Robo'' event with Demcon</a></h1>
				<div class="postcard__subtitle small">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
                 08/06/2023
					</time>
				</div>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt">An interesting event for our TUBERS project occurred in the first week of June. On June 3rd
2023, Demcon participated in the ICD “Robo” event as an exhibitor.</div>
				<ul class="postcard__tagbox">
					
          <Link
                   to ='/The TUBERS tech joins the ICD Robo event with Demcon'
                    className="buttonaki">
                    Read More
                  </Link>
				</ul>
			</div>
		</article>
    </div>
              
              </section>


              <section >
                	<div class="container py-2">
		<div class="h1 text-center text-dark" id="pageHeaderTitle"></div>

		<article class="postcard light blue">
			<a class="postcard__img_link" >
				<img class="postcard__img"  title="" src={blogimg3}/>
			</a>
			<div class="postcard__text t-dark">
				<h1 class="postcard__title blue"><a >The 1st TUBERS project's event participation</a></h1>
				<div class="postcard__subtitle small">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
                 24/10/2022
					</time>
				</div>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt">The TUBERS Project has participated in the launch event: Paving the way towards the next
generation of R&amp;I excellence in AI, Data, and Robotics.</div>
				<ul class="postcard__tagbox">
					
          <Link
                   to ='/The 1st TUBERS projects event participation'
                    className="buttonaki">
                    Read More
                  </Link>
				</ul>
			</div>
		</article>
    </div>
              
              </section>

			  
              <section >
                	<div class="container py-2">
		<div class="h1 text-center text-dark" id="pageHeaderTitle"></div>

		<article class="postcard light blue">
			<a class="postcard__img_link" >
				<img class="postcard__img"  title="" src={blogimg1}/>
			</a>
			<div class="postcard__text t-dark">
				<h1 class="postcard__title blue"><a>The ultimate insight look at the TUBERS kick-off meeting</a></h1>
				<div class="postcard__subtitle small">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
                 13/10/2022
					</time>
				</div>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt">The beginning of an engaging journey for the TUBERS project consortium commenced and was
sealed with a kick-off meeting! On October 5th 2022,</div>
				<ul class="postcard__tagbox">
					
          <Link
                   to ='/The ultimate insight look at the TUBERS kick-off meeting'
                    className="buttonaki">
                    Read More
                  </Link>
				</ul>
			</div>
		</article>
    </div>
              
              </section>
         
          </div>


          
        </div>
      </div>
    </div>
  )
}

export default BlogList