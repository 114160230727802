import React from "react";
import "../../assets/scss/styles.scss"
import Img from 'react-cloudinary-lazy-image'
import Map from '../map.js'
import banner from '../../assets/img/banner.png'
import DarkFooter from "../../components/Footers/DarkFooter.js";
import BlogList from "../../components/BlogList";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "../../components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "../../components/Headers/LandingPageHeader.js";
import Blog2 from "../../components/Blog2";



function Webinar() {
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper">
        <section className="section section-about-us" id='workshop'>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-left" md="8" >
                <h2 className="title" style={{color:'#206a72',marginTop:'50px',marginBottom:'10px'}}>Online Workshop</h2>
              <h3 style={{color:'#1da4b2', fontWeight:'600',marginBottom:'10px'}}>"Introduction to TUBERS"</h3>
              <p style={{marginBottom:'5px',}}><b style={{color:'#206a72'}}>Date:</b> October 1st, 2024</p>
              <p style={{marginBottom:'20px',}}><b style={{color:'#206a72'}}>Time:</b> 15.00 - 16.00 CET</p>
              <Button id='button' style={{color:'whitesmoke'}} href='https://zoom.us/meeting/register/tJIocuupqT8rEtViiycQv7BB4xNl9TwPxwK-'>Register here</Button>
              <br/><br/>
              <div style={{padding:'14px',backgroundColor:'#cce9ed80', borderRadius:'10px'}}>
              <h4 style={{color:'#206a72', fontWeight:'600', marginTop:'0px'}}>Agenda</h4>
              <b style={{color:'#1da4b2',fontSize:'1.2em'}}>Current Challenges in Water Infrastructure Asset Management</b>
              <p>Presented by an Asset Management Expert from Evides, this session will focus on the pressing issues in water management today.</p>
              <b style={{color:'#1da4b2',fontSize:'1.2em'}}>
              TUBERS Robotics: Mission & Ambition
              </b>
              <p>Joël Vanden Bosch from ALSYMEX will showcase the latest advancements in robotic inspection and maintenance, designed to create more efficient, sustainable, and cost-effective water networks.</p>
<p>This workshop will offer actionable insights and a chance to learn about the technologies that are being developed by our TUBERS consortium. If you are looking for ways to improve water infrastructure resilience and performance, this is a fantastic opportunity to gain knowledge directly from industry leaders.</p>
             
             <p>We look forward to your participation!</p> </div>
             <br/>   <br/>
               <img src={banner} style={{borderRadius:'10px'}}></img>
             </Col>
            </Row>
            </Container>
          
            </section>
       
        <DarkFooter />
        
      </div>
    </>
  );
}

export default Webinar;
