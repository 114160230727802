/*

=========================================================
* Now UI Kit React - v1.5.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-kit-react
* Copyright 2021 Creative Tim (http://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-kit-react/blob/main/LICENSE.md)

* Designed by www.invisionapp.com Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import {Helmet} from "react-helmet";
import favicon from './components/Navbars/tubers_logo3.svg'

// styles for this kit
import "./assets/css/bootstrap.min.css";
import "./assets/scss/now-ui-kit.scss";
import "./assets/demo/demo.css?v=1.5.0";
import "./assets/demo/nucleo-icons-page-styles.css?v=1.5.0";
// pages for this kit
import Article1 from "./components/Article1";
import Article2 from "./components/Article2";
import Article3 from "./components/Article3";
import Article4 from "./components/Article4";
import Article5 from "./components/Article5";
import LandingPage from "./views/pages/LandingPage.js";
import Project from "./views/pages/Project.js"; 
import PrivacyPolicy from "./views/pages/PrivacyPolicy";
import ProjectPage from "./views/pages/Project.js";
import Partners from "./views/pages/Partners.js";
import Outcomes from "./views/pages/Outcomes";
import News from "./views/pages/News";
import { hotjar } from 'react-hotjar';
import PiwikPro from '@piwikpro/react-piwik-pro';
import Article6 from "./components/Article6";
import Article7 from "./components/Article7";
import Article8 from "./components/Article8";
import Article9 from "./components/Article9";
import Blog1 from "./components/Blog1";
import Article10 from "./components/Article10";
import Article11 from "./components/Article11";
import Webinar from "./views/pages/webinar";
import Blog3 from "./components/Blog3";
import Article12 from "./components/Article12";

PiwikPro.initialize('92b78e49-755b-404c-bbf7-59866809f705', 'https://pantheon-project.containers.piwik.pro');

hotjar.initialize('3339344', '6');

// Identify the user
hotjar.identify('USER_ID', { userProperty: 'value' });

// Add an event
hotjar.event('button-click');

// Update SPA state
hotjar.stateChange('https://tubers-project.eu/');

// Check if Hotjar has been initialized before calling its methods
if (hotjar.initialized()) {
  hotjar.identify('USER_ID', { userProperty: 'value' });
}

ReactDOM.render(
  <BrowserRouter>
  <Helmet>
        <meta charSet="utf-8"/>
        <title>TUBERS</title>
        <link rel="image_src" href="/static/media/tubers_logo2.svg" />
        <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
        <meta name="description" content="App Description" />
        <meta name="theme-color" content="#008f68" />

  </Helmet>
    <Switch>
      <Switch>
        <Route exact path="/" render={(props) => <LandingPage {...props} />} />
        <Route path="/project" component={ProjectPage} />
        <Route path="/partners" component={Partners} />
        <Route path="/outcomes" component={Outcomes} />
        <Route path="/workshop" component={Webinar} />
        <Route path="/news" component={News} />
        <Route path="/The ultimate insight look at the TUBERS kick-off meeting" component={Article1} />
        <Route path="/The 1st TUBERS projects event participation" component={Article2} />
        <Route path="/The ultimate insight look at the TUBERS kick-off meeting" component={Article1} />
        <Route path="/The TUBERS tech joins the ICD Robo event with Demcon" component={Article3} />
        <Route path="/Demcon attends the Vision, Robotics and Motion event" component={Article4} />
        <Route path="/Project TUBERS attends Smart Water Utilities 2023" component={Article5} />
        <Route path="/Project TUBERS & RIMA Alliance:Main Challenges in Inspection & Maintenance Robotics" component={Article6} />
        <Route path="/TUBERS attending the WWT Innovation & Smart Water Conference 2024" component={Article7} />
        <Route path="/TUBERS research gets published for the first time" component={Article8} />
        <Route path="/M21 Meeting finds TUBERS in Bordeaux" component={Article9} />
        <Route path="/Challenges and Innovations in the Water Industry" component={Blog1} />
        <Route path="/TUBERS at the France-Morocco Economic Days" component={Article10} />
        <Route path="/TUBERS project joins the UK Utility Week 2024" component={Article11} />
        <Route path="/The TUBERS snake-like robot for continuous inspection of drinking water distribution networks" component={Blog3} />
        <Route path="/TUBERS comes back to the Vision, Robotics &amp; Motion Trade Fair" component={Article12} />
      </Switch>
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
