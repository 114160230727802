import React, { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom";
import blogimg1 from '../assets/img/snake.png'

import ExamplesNavbar from './Navbars/ExamplesNavbar';
import DarkFooter from './Footers/DarkFooter';
import {Helmet} from "react-helmet";

const Blog3 = () => {



  return (
    <>   
    <div id="layout" className="pure-g">
       <ExamplesNavbar />
      
      <div className="content pure-u-1 pure-u-md-3-4">
        <div>
          <div className="posts">
            

          <section className="post" style={{display:'grid'}}>
                <div className='singleblog'>
                <header className="post-header">
                  <h3 style={{color:'#07353d'}} className="post-title pt-3">
                  The TUBERS snake-like robot for continuous inspection of drinking water distribution networks
                  </h3>
                  <div class="postcard__subtitle">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
                 24/10/2024
					</time>
				</div>
        <br/>
                 
                </header>
                <div className="post-description">
                <img style={{float:'left',margin:' 0px  20px 20px 0px'}} src={blogimg1} title=""  width="520" height="291" />
                <h4>Societal Challenge</h4>
                <p style={{textAlign:'justify'}}>
               Drinking water companies will have to replace and repair a large portion of their distribution networks in the coming
decennia due to ageing and degradation of pipes. To take on this task cost-efficiently, it is important to form a clear
picture of the current state of every pipe.
</p>
<p style={{textAlign:'justify'}}>The use of the Tubers snake-like robot will greatly increase the information that these asset management decisions
rely on. This means that the right pipe will be replaced at the right time (preventing premature replacement, which is
the destruction of capital, and overdue replacement, which can lead to costly malfunctions). In situ localisation of
leakages and other emergencies will provide information on immediate problems in the network.</p>
<h4>Product Innovation</h4>

<p style={{textAlign:'justify'}}>Since 2017, Demcon high-tech systems has been developing the snake-like robot system. This system allows drinking
water companies to move from ‘risk-based’ maintenance to ‘condition-based’ maintenance; numbers tell the tale!</p>

<p style={{textAlign:'justify'}}>The snake-like robot system consists of:</p>
<ul style={{fontSize:'16px'}}>
  <li>autonomously operating snake-like robots that navigate and inspect inside the network 24/7. Equipped with
various sensors, they gather information on pipe condition, exact pipe location, water condition and network
defects.</li>
<li>an infrastructure of base stations used to collect navigation and inspection data from the robots, charge the
robots wirelessly and provide the robots with inspection routes.</li>
<li>and mission planner software that provides an interface for the asset manager to collect data and direct the
inspections.</li>
</ul>
<p>The autonomy of the robots makes this solution a groundbreaking and innovative concept.</p>
<h4>The TUBERS Ecosystem</h4>

<p style={{textAlign:'justify'}}>Within the TUBERS ecosystem, other products have been developed to form a complete asset management package.
The autonomous inspection capabilities of the snake-like robot system are combined with novel ultrasound sensor
technology to detect leaks, AI-driven decision support software to process all collected data and a robotic platform to
repair leaks cost-efficiently. This ecosystem is developed by the European consortium of Demcon high-tech systems,
<a id='hyperlink'href="https://www.twi-hellas.com/"> TWI Hellas, </a> <a id='hyperlink'href="https://www.essex.ac.uk/">the University of Essex, </a> and <a id='hyperlink'href="https://bendabl.com/">Bendabl, </a>. Primary partners in the consortium are three Dutch drinking water
companies: <a id='hyperlink' href="https://www.brabantwater.nl/">Brabant Water, </a> <a id='hyperlink'href="https://www.evides.nl/">Evides, </a>  and <a id='hyperlink'href="https://www.vitens.nl/">Vitens </a>. Together with secondary partners, such as 
<a id='hyperlink' href='https://seswater.co.uk/'> SES Water</a>,
<a id='hyperlink' href='https://www.south-staffs-water.co.uk/'> South Staff Water</a>, 
<a id='hyperlink' href='https://www.gruppocap.it/en'> Gruppo Cap</a>, <a id='hyperlink' href='https://www.eaudugrandlyon.com/'>Eau du Grand Lyon </a> 
and <a id='hyperlink' href='https://www.eaudeparis.fr/'> Eau de Paris</a>, 
<a id='hyperlink' href='https://www.eureau.org/'>Eur Eau</a> and <a id='hyperlink' href='https://www.ofwat.gov.uk/'>Ofwat</a>, they provide the consortium with
valuable information to make sure the TUBERS ecosystem is developed to measure up to the demands of the future.</p>



                 
              



                
                </div>
                
                
                </div>
               
                
              </section>
               
                    
          </div>


          
        </div>
      </div>
      <DarkFooter/>
    </div>
    </>
  )
}

export default Blog3