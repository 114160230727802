import React, { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom";


import blogimg1 from '../assets/img/Trade.png'
import pdffile from './pdffile.pdf'
import icon from '../assets/img/pdficon.svg'

import ExamplesNavbar from './Navbars/ExamplesNavbar';
import DarkFooter from './Footers/DarkFooter';

const Article12 = () => {
  

  return (
    <div id="layout" className="pure-g">
       <ExamplesNavbar />
      <div className="content pure-u-1 pure-u-md-3-4">
        <div>
          <div className="posts">
            

          <section className="post" style={{display:'grid'}}>
                <div className='singleblog'>
                <header className="post-header">
                  <h3 style={{color:'#07353d'}} className="post-title pt-3">
                  TUBERS comes back to the Vision, Robotics & Motion Trade Fair        
                  </h3>
                  <div class="postcard__subtitle">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
                 12/06/2024
					</time>
				</div>
        <br/>
                 
                </header>
                <div className="post-description">
                <img style={{float:'left',margin:' 0px  20px 20px 0px'}} src={blogimg1} title=""  width="340" height="291" />
               <p style={{textAlign:'justify'}}>Our TUBERS project took part in the <a  id='hyperlink' href='https://vision-robotics.nl/en/'>Vision, Robotics and Motion Trade Fair</a> through our
partner Demcon <a  id='hyperlink' href='https://tubers-project.eu/Demcon%20attends%20the%20Vision,%20Robotics%20and%20Motion%20event'>for a second time</a>. This year, the event happened on Wednesday 5th and
Thursday 6th of June, 2024, in the Brabanthallen in s’-Hertogenbosch.
</p>
<p style={{textAlign:'justify'}}>
In total, the event had 120 exhibitors, from technological manufacturers to suppliers and
advisors. Plus, a new pillar was added focusing on Digital Innovation, with over 30 exhibitors
and speakers sharing smart digitalisation solutions.</p>
<p style={{textAlign:'justify'}}>
Located at booth 40, <a id='hyperlink' href='https://demcon.com/'>Demcon</a> showcased its snake-like robot, an integral part of the TUBERS
inspection and maintenance system. The fair was a fantastic platform for networking and
sharing insights, as well as an opportunity to showcase how we aim to improve the water
industry infrastructure, avoid leakages and reduce non-revenue water.
</p>
<p style={{textAlign:'justify'}}>
As the TUBERS project continues to push the boundaries of innovation in the water industry,
the insights gained from this event will undoubtedly contribute to ongoing efforts to improve
infrastructure and promote sustainability.
</p>
<p style={{textAlign:'justify'}}>
The next event will occur on June 11th and 12th of 2025, so we’ll be hopefully attending it once
more.
</p>


              



                
                </div>
                
                
                </div>
               
                
              </section>
               
                    
          </div>


          
        </div>
      </div>
      <DarkFooter/>
    </div>
  )
}

export default Article12