import React from "react";
import "../../assets/scss/styles.scss"
import DarkFooter from "../../components/Footers/DarkFooter.js";
import snake from '../../assets/img/snake.svg'
import base from '../../assets/img/base.svg'
import decision from '../../assets/img/decision.svg'
import flex from '../../assets/img/flex.svg'


// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "../../components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "../../components/Headers/LandingPageHeader.js";



function ProjectPage() {
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper">
        
 
        <section className="section section-about-us" id='summary'>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title" style={{color:'#206a72',marginTop:'50px'}}>Project Summary</h2>
                <h5 className="description" style={{textAlign:'justify',color:'#07353d'}}>
                The water management industry is deeply affected by its construction inconsistencies. 
                Specifically, new water pipes coexist with the older distribution system dating back 50 years. As a result, failures occur, and water leakages emerge. 
                Yet, water leakage problems are challenging and difficult to contain or manage effectively.
                </h5>
                <h5 className="description" style={{textAlign:'justify',color:'#07353d'}}>
                Per year, there is a 20% average loss of treated water globally. So, assuming that leakages are left unattended, they can result in around 31.5 million m3/year of water loss and €16 million in revenue.
                 At the same time, processing water and wastewater is energy-intensive and accounts for around 40% of municipal energy use, commonly produced by burning fossil fuels with carbon dioxide (CO2), 
                 methane (CH4) and nitrous oxide (N2O) emissions of up to 5%. Thus, a definitive solution should be found regarding problems with groundwater management,
                source water protection, and the treatment of existing and new wastewater.
                </h5>
                <h5 className="description" style={{textAlign:'justify',color:'#07353d'}}>
                TUBERS aims to build an ecosystem of robotic tools to holistically address the inspection and repair of pipelines within water networks and beyond by taking a radical approach and moving 
                away from conventional solutions. This ecosystem will consist of a Snake-like Resident Robot, a High-accuracy inspection system, a Decision Support System (DSS) and Soft Repair Robots; 
                TUBERS will perform day and night inspections over long distances & targeted in-situ repairs with a novel repair deployment mechanism to reduce inspection & maintenance costs.
                </h5>
                <h5 className="description" style={{textAlign:'justify',color:'#07353d'}}>
                The green initiative aims to save 158GWh of energy and 79,000 tonnes of CO2 emissions in a 5-year period. TUBERS aspires to bring about ground-breaking digital transformation in scientific research, 
                economic development, sustainability and society in Europe at large.
                </h5>
              </Col>
            </Row>
            </Container>
            </section>
            <section id='objectives'>
        <div className="section section-team text-center" style={{backgroundColor:'#07353d'}}>
        <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
              <h2 className="title" style={{color:'#dadada'}}>Objectives</h2>
             
              <ul id='ul2'>
                <li id='list'>Upgrade the snake-like resident robot and implement the necessary navigation software for autonomous operation over long distances and effective negotiation of pipeline features such as junctions and bends.</li><br/>
                <li id='list'>Develop a modular soft-robotic platform with a locomotion mechanism and a novel mechanism capable of inspecting and repairing pipe segments from the inside.</li><br/>
                <li id='list'>Research and develop a high-accuracy ultrasonic inspection system and the respective interpretation modules that can detect leaks and, most importantly, measure wall thickness loss.</li><br/>
                <li id='list'>Research and develop a Decision Support System powered by Explainable Machine Learning algorithms for holistic inspection and maintenance planning.</li><br/>
              </ul>         
              </Col>
            </Row>
            </Container>
        </div>
        </section>
        <section id='tech'>
        <div className="section section-team text-center" style={{backgroundColor:'#1e646c'}}>
        <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
              <h2 className="title" style={{color:'#dadada'}}>Technologies</h2>
             <div id='cardz'>
              <div class="card-wrap">
  <div class="card-header one">
    <img src={snake}></img>
  </div>
  <div class="card-content">
    <h1 class="card-title"><strong>Resident Snake-like Robot</strong></h1>
    <ul id='ulu' style={{padding:'0',width:'80%',textAlign:'left',color:'#07353d'}}>
      <li>Modular design for extended functionality</li>
      <li>Accurate water network navigation </li>
      <li>Continuous pipeline scanning</li>
    </ul>
 </div>
</div>
<div class="card-wrap">
<div class="card-header one">
    <img src={decision}></img>
  </div>
  <div class="card-content">
    <h1 class="card-title"><strong>Decision Support System (DSS)</strong></h1>
    <ul id='ulu' style={{padding:'0',width:'80%',textAlign:'left',color:'#07353d'}}>
      <li>Assistance with the decision-making process of inspection and maintenance scheduling</li>
      <li>Structural defect identification </li>
      <li>Data logging and archiving</li>
    </ul>
 </div>
</div>
<div class="card-wrap">
<div class="card-header one">
    <img src={flex}></img>
  </div>
  <div class="card-content">
    <h1 class="card-title"><strong>Soft-robotic modular Robot</strong></h1>
    <ul id='ulu' style={{padding:'0',width:'80%',textAlign:'left',color:'#07353d'}}>
      <li>Accurate detection of leaks</li>
      <li>Novel repair deployment mechanism for pipeline repairs</li>
    </ul>
 </div>
</div>
<div class="card-wrap">
<div class="card-header one">
    <img src={base}></img>
  </div>
  <div class="card-content">
    <h1 class="card-title"><strong>Base Station</strong></h1>
    <ul id='ulu' style={{padding:'0',width:'80%',textAlign:'left',color:'#07353d'}}>
      <li>Provision of resident robot charging </li>
      <li>Wireless data exchange between resident robot and DSS</li>
      <li>Retrieval and delivery of mission plans</li>
    </ul>
 </div>
</div>
</div>
              </Col>
            </Row>
            </Container>
        </div>
        </section>
        <DarkFooter />
        
      </div>
    </>
  );
}

export default ProjectPage;
